import { TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";

/**
 * Компонент текстового поля с отложенным вызовом onChange.
 * @param {function} onChange - Обработчик изменения текстового поля.
 * @param {number} delay - Задержка перед вызовом onChange, ms. По умолчанию 1000.
 * @param {object} props - Все остальные props передаются в TextField.
 *
 * Возвращает компонент TextField, но при этом onChange вызывается с задержкой
 * delay ms. Это бывает полезно, когда мы не хотим, чтобы onChange вызывался
 * при каждом изменении текстового поля (например, чтобы не делать лишних
 * запросов к серверу).
 *
 * При размонтировании компонента таймер очищается, чтобы не было утечки памяти.
 */
const DelayTextField = ({ onChange, delay = 1000, ...props }) => {
    const [value, setValue] = useState(props.value || "");
    const timeoutRef = useRef(null);

    const onChangeHandler = (e) => {
        const newValue = e.target.value;
        setValue(newValue);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            onChange(newValue);
        }, delay);
    };

    useEffect(() => {
        // Очищаем таймер при размонтировании компонента
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return <TextField {...props} value={value} onChange={onChangeHandler} />;
};

export default DelayTextField;
