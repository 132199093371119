import api from "../http";

export default class KosaService {
    static async getNextId() {
        return api.get("/kosa");
    }

    static async getByOrderItemId(orderItemId) {
        return api.get(`/kosa/${orderItemId}`);
    }

    static async get(start, stop) {
        return api.get(`/kosa/${start}/${stop}`);
    }

    static async save(orderItem) {
        return api.post("/kosa", { orderItem: orderItem });
    }

    static async checkSns(sns) {
        return api.post("/kosa/check-sns", { sns: sns });
    }

    static async from(size = null, start = null) {
        if (!size) return api.get("/kosas/from/");
        else if (!start) return api.get(`/kosas/from/${size}`);
        else return api.get(`/kosas/from/${start}/${size}`);
    }

    static async history(sn) {
        return api.get(`/kosas/history/${sn}`);
    }
}
