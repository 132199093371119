import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { IconButton, Stack, Table, TableBody, TableCell, TableRow, TextField, Tooltip } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import useErrorHandler from "../../services/error-handler";
import KosaService from "../../services/kosa-service";
import { DelayTextField } from "../StyledComponents";
import { stateColor, stateStr, STATES } from "../../models/kosaState";

const DEFAULT_CELL_STYLE = {
    border: "2px solid black",
    padding: "0 6px",
    color: "black",
    fontSize: "1em",
    cursor: "default",
};

const COLUMNS_SIZE = 10;
const ROWS_SIZE = 20;

//=================================================================================================
//store
class Store {
    load = false;
    items = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    async _load(start = null) {
        this.load = true;
        try {
            const answer = await KosaService.from(COLUMNS_SIZE * ROWS_SIZE, start);
            this.items = new Map(Object.entries(answer.data).map(([key, value]) => [parseInt(key), value]));
        } catch (e) {
            console.warn(e);
            throw new Error("Ошибка загрузки данных");
        } finally {
            this.load = false;
        }
    }

    async loadNext() {
        let m = this.maxItemId();
        if (!m) m = null;
        else m = m + COLUMNS_SIZE * ROWS_SIZE;
        await this._load(m);
    }

    async loadPrev() {
        let m = this.minItemId();
        if (!m) m = null;
        else m = m - 1;
        m = Math.max(m, COLUMNS_SIZE * ROWS_SIZE);
        await this._load(m);
    }

    async loadFrom(sn = null) {
        if (!sn) {
            await this._load();
            return;
        }
        const m = Math.max(sn, COLUMNS_SIZE * ROWS_SIZE);
        await this._load(m);
    }

    maxItemId() {
        if (this.items.size === 0) return undefined;
        else return Math.max(...this.items.keys());
    }

    minItemId() {
        if (this.items.size === 0) return undefined;
        else return Math.min(...this.items.keys());
    }
}

const store = new Store();

//=================================================================================================
const History = () => {
    const renderRows = () => {
        const out = [];
        for (let i = STATES.READY; i <= STATES.ORDERED; i++) {
            out.push(
                <TableRow key={i} sx={{ backgroundColor: stateColor(i) }}>
                    <TableCell>{stateStr(i)}</TableCell>
                </TableRow>
            );
        }
        return out;
    };

    return (
        <Table size="small" sx={{ height: "100%", width: "auto" }}>
            <TableBody>{renderRows()}</TableBody>
        </Table>
    );
};

//=================================================================================================
const Item = ({ sn, data, onClick }) => {
    const tableCellStyle = () => {
        if (data && "state_id" in data) {
            return { ...DEFAULT_CELL_STYLE, backgroundColor: stateColor(data.state_id) };
        }
        return DEFAULT_CELL_STYLE;
    };

    const onItemClick = () => {
        onClick(sn);
    };

    const content = (
        <TableCell sx={tableCellStyle()} onClick={onItemClick}>
            {sn}
        </TableCell>
    );

    return data && "state_id" in data ? <Tooltip title={stateStr(data.state_id)}>{content}</Tooltip> : content;
};

//=================================================================================================
const KosaList = ({}) => {
    const pushError = useErrorHandler();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        try {
            store.loadFrom();
        } catch (e) {
            pushError(e);
        }
    };

    const nextPage = async () => {
        try {
            await store.loadNext();
        } catch (e) {
            pushError(e);
        }
    };

    const prevPage = async () => {
        try {
            await store.loadPrev();
        } catch (e) {
            pushError(e);
        }
    };
    const from = async (sn) => {
        try {
            await store.loadFrom(sn);
        } catch (e) {
            pushError(e);
        }
    };

    const onItemClick = (sn) => {
        navigate("/kosas/" + sn, { state: { backgroundLocation: location } });
    };

    const renderRows = () => {
        const items = store.items;
        let max = store.maxItemId();
        const min = store.minItemId();
        if (!max) return null;
        const out = [];
        while (max >= min) {
            max = Math.floor(max / COLUMNS_SIZE) * COLUMNS_SIZE;
            const col = [];
            for (let i = 0; i < COLUMNS_SIZE; i++) {
                col.push(<Item key={max + i} sn={max + i} data={items.get(max + i)} onClick={onItemClick} />);
            }
            out.push(<TableRow key={max}>{col}</TableRow>);
            max -= COLUMNS_SIZE;
        }
        return out;
    };

    return (
        <Stack direction="row" spacing={2}>
            <History />
            <Stack spacing={1}>
                <DelayTextField onChange={from} size="small" label="SN" />
                <Table size="small" sx={{ padding: 0 }} fullWidth>
                    <TableBody>{renderRows()}</TableBody>
                </Table>
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <Tooltip title="Предыдущие">
                        <IconButton onClick={prevPage}>
                            <NavigateBeforeIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Следующие">
                        <IconButton onClick={nextPage}>
                            <NavigateNextIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default observer(KosaList);
