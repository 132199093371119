import { useState, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Menu, MenuItem, ListItemIcon, IconButton, Button, Stack } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PeopleIcon from "@mui/icons-material/People";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";

import { AuthContext } from "../../";
import { ReactComponent as Logo } from "../../img/logo.svg";
import useMedia from "../../services/media-query";
import styles from "./Header.module.css";
import UserOffice from "./UserOffice";

const buttonStyle = {
    color: "#c6c7c8",
    textTransform: "none",
    fontSize: "1.1rem",
    "&:hover": {
        color: "white",
    },
    "& > svg": {
        height: "1.5rem",
        fill: "#c6c7c8",
    },
    "&:hover > svg": {
        fill: "white",
    },
};

const iconButtonStyle = {
    color: "#c6c7c8",
    "&:hover": {
        color: "white",
    },
};

const Header = () => {
    const { isMobile } = useMedia();
    const { authStore } = useContext(AuthContext);

    const [userMenuAnchor, setUserMenuAnchor] = useState(null);
    const [productionMenuAnchor, setProductionMenuAnchor] = useState(null);
    const [mainMenu, setMainMenu] = useState(null);
    const [showUserOffice, setShowUserOffice] = useState(false);

    const navigate = useNavigate();

    /**
     * обработка события нажатия "Выход" в контексте пользователя
     */
    const handleExitButtonClick = () => {
        authStore.logout();
        setUserMenuAnchor(null);
    };

    const handleUserManagerClick = () => {
        navigate("/users");
        setUserMenuAnchor(null);
    };

    const handlerProductionMenuClick = (item = "") => {
        navigate("/production/" + item);
        setProductionMenuAnchor(null);
        setMainMenu(null);
    };

    const renderProductionMenu = () => {
        return (
            <Menu
                anchorEl={productionMenuAnchor}
                open={Boolean(productionMenuAnchor)}
                onClose={() => setProductionMenuAnchor(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <MenuItem onClick={() => handlerProductionMenuClick()}> Всё </MenuItem>
                <MenuItem onClick={() => handlerProductionMenuClick("kosa")}> Косы </MenuItem>
                <MenuItem onClick={() => handlerProductionMenuClick("adc")}> АЦП </MenuItem>
                <MenuItem onClick={() => handlerProductionMenuClick("controllers")}>Контроллеры</MenuItem>
                <MenuItem onClick={() => handlerProductionMenuClick("loggers")}> Логгеры </MenuItem>
            </Menu>
        );
    };

    const mainItems = [
        {
            name: "Производство",
            action: (e) => setProductionMenuAnchor(e.currentTarget),
            menu: renderProductionMenu,
        },
        {
            name: "Заказы",
            action: () => {
                navigate("/orders");
                setMainMenu(null);
            },
            menu: null,
        },
        {
            name: "Косы",
            action: () => {
                navigate("/kosas");
                setMainMenu(null);
            },
            menu: null,
        },
        {
            name: "Закупки",
            action: () => {
                navigate("/grocery-list");
                setMainMenu(null);
            },
            menu: null,
        },
    ];

    /**
     * отрисовка меню пользователя в зависимости от его роли
     */
    const renderUserMenu = () => {
        // возвращает пункты меню для пользователя с ролью администратор
        const admItems = () => {
            if (authStore.user?.role === 2) {
                return (
                    <MenuItem onClick={handleUserManagerClick}>
                        <ListItemIcon>
                            <PeopleIcon fontSize="small" />
                        </ListItemIcon>
                        Управление пользователями
                    </MenuItem>
                );
            }
        };
        return (
            <Menu
                anchorEl={userMenuAnchor}
                open={Boolean(userMenuAnchor)}
                onClose={() => setUserMenuAnchor(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <MenuItem
                    onClick={() => {
                        setShowUserOffice(true);
                        setUserMenuAnchor(null);
                    }}
                >
                    <ListItemIcon>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    {authStore.user?.login}
                </MenuItem>
                {admItems()}
                <MenuItem onClick={handleExitButtonClick}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Выход
                </MenuItem>
            </Menu>
        );
    };

    const renderMainMenu = () => {
        return (
            <Menu anchorEl={mainMenu} open={Boolean(mainMenu)} onClose={() => setMainMenu(null)}>
                {mainItems.map((item) => (
                    <>
                        <MenuItem key={item.name} onClick={item.action} href={item.href}>
                            {item.name} {item.menu && <ArrowDropDownIcon />}
                        </MenuItem>
                        {item.menu && item.menu()}
                    </>
                ))}
            </Menu>
        );
    };
    const renderMainButtons = () => {
        return mainItems.map((item) => (
            <>
                <Button
                    key={item.name}
                    onClick={item.action}
                    sx={buttonStyle}
                    endIcon={item.menu && <ArrowDropDownIcon />}
                >
                    {item.name}
                </Button>
                {item.menu && item.menu()}
            </>
        ));
    };

    return (
        <>
            <header className={styles.header}>
                <Stack direction="row" spacing={1} justifyContent="space-between">
                    <Button sx={buttonStyle} href="/">
                        <Logo />
                        KrioLab
                    </Button>
                    <Stack direction="row">
                        {isMobile ? (
                            <>
                                <IconButton onClick={(e) => setMainMenu(e.currentTarget)} sx={iconButtonStyle}>
                                    <MenuIcon />
                                    <ArrowDropDownIcon />
                                </IconButton>
                                {renderMainMenu()}
                            </>
                        ) : (
                            renderMainButtons()
                        )}
                        <IconButton onClick={(e) => setUserMenuAnchor(e.currentTarget)} sx={iconButtonStyle}>
                            <PersonIcon />
                            <ArrowDropDownIcon />
                        </IconButton>
                        {renderUserMenu()}
                    </Stack>
                </Stack>
            </header>
            <UserOffice open={showUserOffice} onClose={() => setShowUserOffice(false)} />
            <div className={isMobile ? null : styles.content}>
                <Outlet />
            </div>
        </>
    );
};

export default observer(Header);
