// prettier-ignore
export const STATES = {
    READY           : 1,
    CALIBRATE       : 2,
    CASE            : 3,
    CASE_AND_VERIFY : 4,
    CREATED         : 5,
    CONNECTED       : 6,
    REPEAT          : 7,
    ORDERED         : 8,
};

export const stateColor = (state_id) => {
    // prettier-ignore
    switch (state_id) {
        case STATES.READY           : return '#95CD60';
        case STATES.CALIBRATE       : return '#9BD1F4';
        case STATES.CASE            : return '#F3BB8F';
        case STATES.CASE_AND_VERIFY : return '#E7863A';
        case STATES.CREATED         : return '#FEF07B';
        case STATES.CONNECTED       : return '#AE8DDF';
        case STATES.REPEAT          : return '#F78686';
        case STATES.ORDERED         : return '#C66B9F';
    }
};

export const stateStr = (state_id) => {
    // prettier-ignore
    switch (state_id) {
        case STATES.READY           : return 'Готова'              ;
        case STATES.CALIBRATE       : return 'Калибровка'          ;
        case STATES.CASE            : return 'Заварка'             ;
        case STATES.CASE_AND_VERIFY : return 'Заварена и проверена';
        case STATES.CREATED         : return 'Создана'             ;
        case STATES.CONNECTED       : return 'Подключалась'        ;
        case STATES.REPEAT          : return 'Ремонт'              ;
        case STATES.ORDERED         : return 'Заказана'            ;
    }
};
